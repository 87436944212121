<template>
  <form @submit.prevent="createWorkEquipment">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>Создание</h2>
      <div>
        <UIButton text="Сохранить" leftIcon="bi-save" type="submit" />
      </div>
    </div>
    <div class="row gap-3">
      <dl class="col row gap-2">
        <div class="row gap-2">
          <div>
            <dt class="col">Наименование:</dt>
            <dl class="col">
              <EditableInput v-model="data.name" editable required />
            </dl>
          </div>

          <div>
            <dt class="col">Описание:</dt>
            <dl class="col">
              <EditableInput v-model="data.description" editable required />
            </dl>
          </div>

          <div>
            <dt class="col">Тариф:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.rate"
                type="number"
                editable
                required
              />
            </dl>
          </div>
        </div>
      </dl>
      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Код:</dt>
            <dl class="col">
              <EditableInput v-model="data.code" editable required />
            </dl>
          </div>

          <div>
            <dt class="col">Статус:</dt>
            <dl class="col">
              <EditableSelect
                v-model="data.statusId"
                :options="status"
                editable
                required
                valueKey="id"
                label="Статус"
              />
            </dl>
          </div>
        </div>
      </dl>
    </div>
  </form>
</template>

<script>
import UIButton from "@/components/UI/Button";
import EditableInput from "@/components/UI/EditableInput";
import EditableSelect from "@/components/UI/EditableSelect";
import { API_StatusList } from "@/services/api";
import { API_qualification_create } from "@/services/references/qualification";

export default {
  name: "enproCUWorkEquipmentCodeCreate",
  components: { UIButton, EditableInput, EditableSelect },
  data() {
    return {
      data: {
        name: "",
        description: "",
        code: "",
        rate: 0,
        statusId: null,
      },
      status: [],
    };
  },
  mounted() {
    API_StatusList().then((res) => {
      this.status = res;
    });
  },
  methods: {
    createWorkEquipment() {
      API_qualification_create(this.data).then((res) => {
        this.$router.push({
          path: `/references/work-equipment/${res.id}`,
        });
      });
    },
  },
};
</script>

<style scoped></style>
